<template>
  <v-dialog v-model="dialog" width="550" height="480" persistent>
    <v-card class="pa-0" style="border: 0px !important">
      <v-card-title class="px-4 py-4">
        <div class="dialog-title">{{ title }}</div>
        <v-btn class="dialog-btn-close" id="btnCloseX" icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="dialog-body">
        <v-form ref="form" v-model="valid" lazy-validation class="pa-0 ma-0">
          <SelectSimple
            class="mb-4"
            id="selectNature"
            :disabled="true"
            :label="txt.nature"
            v-model="incidenceForEdit.rubricNatureCode"
            :items="incidenceNatureList"
          >
          </SelectSimple>
          <SelectSimple
            class="mb-4"
            id="selectType"
            :disabled="false"
            :hasSelectAll="false"
            :label="txt.typeNature"
            v-model="incidenceForEdit.rubricTypeCode"
            :items="natureTypeList"
          >
          </SelectSimple>
          <SelectSimple
            class="mb-4"
            id="selectCp"
            :disabled="false"
            :hasSelectAll="false"
            :label="txt.cp"
            v-model="incidenceForEdit.inssIncidenceCode"
            :items="incidenceINSSList"
          >
          </SelectSimple>
          <SelectSimple
            class="mb-4"
            id="selectFgts"
            :disabled="false"
            :hasSelectAll="false"
            :label="txt.fgts"
            v-model="incidenceForEdit.fgtsIncidenceCode"
            :items="incidenceFGTSList"
          >
          </SelectSimple>
          <SelectSimple
            class="mb-4"
            id="selectIrrf"
            :disabled="false"
            :hasSelectAll="false"
            :label="txt.irrf"
            v-model="incidenceForEdit.irrfIncidenceCode"
            :items="incidenceIRRFList"
          >
          </SelectSimple>
          <SelectSimple
            class="mb-4"
            id="selectCPRP"
            :disabled="false"
            :hasSelectAll="false"
            :label="txt.cprp"
            v-model="incidenceForEdit.cprpIncidenceCode"
            :items="incidenceCPRPList"
          >
          </SelectSimple>
          <SelectSimple
            class="mb-4"
            id="selectUsesTetoRemun"
            :disabled="false"
            :hasSelectAll="false"
            :label="txt.usesTetoRemun"
            v-model="incidenceForEdit.usesTetoRemun"
            :items="usesTetoRemunList"
          >
          </SelectSimple>
          <v-textarea
            rows="4"
            cols="40"
            minlength="0"
            maxlength="300"
            :counter="300"
            v-model="incidenceForEdit.notes"
            required
            hide-details="auto"
            label="Observação ou Embasamento  legal *"
            dense
            outlined
          >
          </v-textarea>
          <div class="text-left pt-8" style="font-size: 14px; color: #575767; font-weight: 400">
            * Campos de preenchimento obrigatório
          </div>
          <div class="text-red pl-2" style="color: #610006" v-if="invalidForm">
            Preencher todos os campos obrigatórios
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="dialog-actions">
        <v-spacer />
        <v-btn class="btn-ag-tertiary" id="btnClose" @click="dialog = false">{{ txt.btnClose }}</v-btn>
        <v-btn class="btn-ag-primary" id="btnConfirmOredit" @click="save()" :disabled="!isEnableSave">{{
          txt.btnSave
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { eventBus } from '../../../../../main.js';
export default {
  name: 'ModalIncidenceNatureEdit',
  components: {
    SelectSimple: () => import('./../../../commons/SelectSimple.vue'),
  },
  data() {
    return {
      dialog: false,
      incidenceForEdit: {},
      invalidForm: false,
      valid: true,
      rules: {
        requiredJust: (just) => !!just || 'Preencha uma observação ou embasamento legal',
      },
      txt: {
        nature: 'Natureza',
        typeNature: 'Tipo',
        cp: 'CP',
        irrf: 'IRRF',
        fgts: 'FGTS',
        cprp: 'CPRP',
        usesTetoRemun: 'Teto Remuneratório',
        btnClose: 'Cancelar',
        btnSave: 'Salvar',
      },
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
    };
  },
  created() {
    this.$on('open', this.open);
  },
  props: {
    title: {
      type: String,
      require: true,
      default: '',
    },
    incidence: {
      type: Object,
      require: true,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
    ...mapGetters('cadastrosGerenciais/analysisParametersModule', {
      incidenceNatureList: 'getIncidenceNatureList',
      natureTypeList: 'getNatureTypeList',
      incidenceIRRFList: 'getIncidenceIRRFList',
      incidenceFGTSList: 'getIncidenceFGTSList',
      incidenceCPRPList: 'getIncidenceCPRPList',
      incidenceINSSList: 'getIncidenceINSSList',
      usesTetoRemunList: 'getUsesTetoRemunList',
    }),
    isEnableSave() {
      return (
        this.incidenceForEdit.rubricNatureCode !== null &&
        this.incidenceForEdit.rubricTypeCode !== null &&
        this.incidenceForEdit.inssIncidenceCode !== null &&
        this.incidenceForEdit.fgtsIncidenceCode !== null &&
        this.incidenceForEdit.irrfIncidenceCode !== null &&
        this.incidenceForEdit.cprpIncidenceCode !== null &&
        this.incidenceForEdit.usesTetoRemun !== null &&
        this.incidenceForEdit.notes &&
        this.incidenceForEdit.notes.trim() !== ''
      );
    },
  },
  methods: {
    ...mapActions('cadastrosGerenciais/analysisParametersModule', ['saveIncidenceNature']),
    open() {
      this.incidenceForEdit = {};
      this.incidenceForEdit = cloneDeep(this.incidence);
      this.incidenceForEdit.usesTetoRemun = this.incidence.usesTetoRemun ? 'Sim' : 'Não';
      this.dialog = true;
    },
    save() {
      //@todo implementar regras de validação no componente SelectSimple
      let incidenceLocal = {
        rubricNatureCode: this.incidenceForEdit.rubricNatureCode,
        rubricTypeCode: this.incidenceForEdit.rubricTypeCode,
        inssIncidenceCode: this.incidenceForEdit.inssIncidenceCode,
        fgtsIncidenceCode: this.incidenceForEdit.fgtsIncidenceCode,
        irrfIncidenceCode: this.incidenceForEdit.irrfIncidenceCode,
        cprpIncidenceCode: this.incidenceForEdit.cprpIncidenceCode,
        usesTetoRemun: this.incidenceForEdit.usesTetoRemun == 'Sim',
        notes: this.incidenceForEdit.notes,
        user: `${this.userInfo.firstName} ${this.userInfo.lastName}`,
      };
      try {
        this.saveIncidenceNature(incidenceLocal);
        this.dialog = false;
        this.showToast('Alterações realizadas com sucesso!.', 'success', 78);
      } catch (error) {
        this.showToast('Erro ao alterar incidência da natureza.', 'warning', 78);
      }
    },
    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/design/components/input.scss';
.v-card__title {
  border-bottom: 1px #e7e7fa solid;
}
.dialog-title {
  width: 453px;
  height: 30px;
  color: #182026;
  font-size: 20px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.2px;
  word-wrap: break-word;
}
.dialog-btn-close {
  width: 32px;
  height: 32px;
  padding: 4px;
  margin-left: 29px;
}
.dialog-btn-close i {
  width: 24px;
  height: 24px;
  margin-right: 0px !important;
}
.dialog-body {
  padding: 16px !important;
  min-height: 336px;
  max-height: 336px;
  overflow-y: scroll;
}
.dialog-container {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 4px;
}
.dialog-actions {
  border-top: 1px #e7e7fa solid;
  padding: 16px !important;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "550", height: "480", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-0", staticStyle: { border: "0px !important" } },
        [
          _c(
            "v-card-title",
            { staticClass: "px-4 py-4" },
            [
              _c("div", { staticClass: "dialog-title" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "dialog-btn-close",
                  attrs: { id: "btnCloseX", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "dialog-body" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  staticClass: "pa-0 ma-0",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c("SelectSimple", {
                    staticClass: "mb-4",
                    attrs: {
                      id: "selectNature",
                      disabled: true,
                      label: _vm.txt.nature,
                      items: _vm.incidenceNatureList,
                    },
                    model: {
                      value: _vm.incidenceForEdit.rubricNatureCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.incidenceForEdit, "rubricNatureCode", $$v)
                      },
                      expression: "incidenceForEdit.rubricNatureCode",
                    },
                  }),
                  _c("SelectSimple", {
                    staticClass: "mb-4",
                    attrs: {
                      id: "selectType",
                      disabled: false,
                      hasSelectAll: false,
                      label: _vm.txt.typeNature,
                      items: _vm.natureTypeList,
                    },
                    model: {
                      value: _vm.incidenceForEdit.rubricTypeCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.incidenceForEdit, "rubricTypeCode", $$v)
                      },
                      expression: "incidenceForEdit.rubricTypeCode",
                    },
                  }),
                  _c("SelectSimple", {
                    staticClass: "mb-4",
                    attrs: {
                      id: "selectCp",
                      disabled: false,
                      hasSelectAll: false,
                      label: _vm.txt.cp,
                      items: _vm.incidenceINSSList,
                    },
                    model: {
                      value: _vm.incidenceForEdit.inssIncidenceCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.incidenceForEdit, "inssIncidenceCode", $$v)
                      },
                      expression: "incidenceForEdit.inssIncidenceCode",
                    },
                  }),
                  _c("SelectSimple", {
                    staticClass: "mb-4",
                    attrs: {
                      id: "selectFgts",
                      disabled: false,
                      hasSelectAll: false,
                      label: _vm.txt.fgts,
                      items: _vm.incidenceFGTSList,
                    },
                    model: {
                      value: _vm.incidenceForEdit.fgtsIncidenceCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.incidenceForEdit, "fgtsIncidenceCode", $$v)
                      },
                      expression: "incidenceForEdit.fgtsIncidenceCode",
                    },
                  }),
                  _c("SelectSimple", {
                    staticClass: "mb-4",
                    attrs: {
                      id: "selectIrrf",
                      disabled: false,
                      hasSelectAll: false,
                      label: _vm.txt.irrf,
                      items: _vm.incidenceIRRFList,
                    },
                    model: {
                      value: _vm.incidenceForEdit.irrfIncidenceCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.incidenceForEdit, "irrfIncidenceCode", $$v)
                      },
                      expression: "incidenceForEdit.irrfIncidenceCode",
                    },
                  }),
                  _c("SelectSimple", {
                    staticClass: "mb-4",
                    attrs: {
                      id: "selectCPRP",
                      disabled: false,
                      hasSelectAll: false,
                      label: _vm.txt.cprp,
                      items: _vm.incidenceCPRPList,
                    },
                    model: {
                      value: _vm.incidenceForEdit.cprpIncidenceCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.incidenceForEdit, "cprpIncidenceCode", $$v)
                      },
                      expression: "incidenceForEdit.cprpIncidenceCode",
                    },
                  }),
                  _c("SelectSimple", {
                    staticClass: "mb-4",
                    attrs: {
                      id: "selectUsesTetoRemun",
                      disabled: false,
                      hasSelectAll: false,
                      label: _vm.txt.usesTetoRemun,
                      items: _vm.usesTetoRemunList,
                    },
                    model: {
                      value: _vm.incidenceForEdit.usesTetoRemun,
                      callback: function ($$v) {
                        _vm.$set(_vm.incidenceForEdit, "usesTetoRemun", $$v)
                      },
                      expression: "incidenceForEdit.usesTetoRemun",
                    },
                  }),
                  _c("v-textarea", {
                    attrs: {
                      rows: "4",
                      cols: "40",
                      minlength: "0",
                      maxlength: "300",
                      counter: 300,
                      required: "",
                      "hide-details": "auto",
                      label: "Observação ou Embasamento  legal *",
                      dense: "",
                      outlined: "",
                    },
                    model: {
                      value: _vm.incidenceForEdit.notes,
                      callback: function ($$v) {
                        _vm.$set(_vm.incidenceForEdit, "notes", $$v)
                      },
                      expression: "incidenceForEdit.notes",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "text-left pt-8",
                      staticStyle: {
                        "font-size": "14px",
                        color: "#575767",
                        "font-weight": "400",
                      },
                    },
                    [_vm._v(" * Campos de preenchimento obrigatório ")]
                  ),
                  _vm.invalidForm
                    ? _c(
                        "div",
                        {
                          staticClass: "text-red pl-2",
                          staticStyle: { color: "#610006" },
                        },
                        [_vm._v(" Preencher todos os campos obrigatórios ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "dialog-actions" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "btn-ag-tertiary",
                  attrs: { id: "btnClose" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.txt.btnClose))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn-ag-primary",
                  attrs: {
                    id: "btnConfirmOredit",
                    disabled: !_vm.isEnableSave,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.txt.btnSave))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }